<template>
    <div>
        <ActualizarEmpresas :tipoBd="'CONTA'" />

        <CRow>
            <CCol>
                <CTabs :active-tab.sync="actTab">
                    <CTab >
                        <template slot="title">
                            Retenciones Anuales
                        </template>
                        <CCard class="tarjetaGestoRetenciones mt-2">
                            <CCardHeader>
                                <h4>Resumen de Retenciones Anuales</h4>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md="4">
                                        <multiselect
                                            :allowEmpty="false"
                                            class="mb-2 multSelectGesto"
                                            :custom-label="customLabelEmp"
                                            v-model="empRetAnualesSelected"
                                            selectedLabel=""
                                            deselectLabel=""
                                            selectLabel=""
                                            placeholder="Seleccionar Empresa" 
                                            label="nombreEmpresa" 
                                            track-by="codEmpresa" 
                                            :options="empresasInfo" 
                                            :multiple="false"
                                            :loading="isLoadingEmpresasInfo"
                                            @select="seleccionarEmpresa"></multiselect>
                                    </CCol>
                                    <CCol md="3">
                                        <date-picker
                                            :editable="false"
                                            :clearable="false"
                                            default-panel="year"
                                            :disabled-date="testDisabledDate"
                                            type="year"
                                            class="mb-2"
                                            :lang="langRetencionesPicker" 
                                            v-model="anioPickerSelected"
                                            :confirm="false" 
                                            :confirm-text="'Aceptar'"
                                            @change="seleccionarAnio"></date-picker>
                                    </CCol>
                                </CRow>
                                <CRow v-if="isLoadingRetAnuales">
                                    <CCol md="12">
                                        <center>
                                            <img src="/img/logo2.png" size="custom-size" :height="50" class="imgr mt-3 mb-3" /> Obteniendo Información...
                                        </center>
                                    </CCol>
                                </CRow>
                                <CRow v-else style="overflow-x:auto" v-show="infoHonorarios.length > 0">
                                    <CCol col="12">
                                        <table class="tableGesto mt-2">
                                            <thead>
                                                <th v-for="col in columnasHonorarios" v-bind:key="col.scopedSlots.customRender" :style="{'text-align': col.align}">{{col.title}}</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="hno in infoHonorarios" v-bind:key="hno.mes">
                                                    <td><a style="color:#29235C;" class="font-weight-bold" @click="seleccionarMesRetAnual(hno.mes)">{{hno.mes}}</a></td>
                                                    <td style="text-align: right;">{{hno.montoRenta | currency}}</td>
                                                    <td style="text-align: right;">{{hno.retencion | currency}}</td>
                                                    <td style="text-align: center;">{{hno.factor}}</td>
                                                    <td style="text-align: right;">{{hno.montoAct | currency}}</td>
                                                    <td style="text-align: right;">{{hno.retAct | currency}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>Total</td>
                                                    <td style="text-align:right;" >{{(totales != null)?totales.montoRenta:"0" | currency}}</td>
                                                    <td style="text-align:right;" >{{(totales != null)?totales.retencion:"0" | currency}}</td>
                                                    <td style="text-align:right;" ></td>
                                                    <td style="text-align:right;" >{{(totales != null)?totales.montoAct:"0" | currency}}</td>
                                                    <td style="text-align:right;" >{{(totales != null)?totales.retAct:"0" | currency}}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                            </CCardFooter>
                        </CCard>
                    </CTab>
                    <CTab>
                        <template slot="title">
                            Libro de Retenciones
                        </template>
                        <CCard class="tarjetaGestoRetenciones mt-2">
                            <CCardHeader>
                                <h4>Libro de Retenciones</h4>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md="4">
                                        <multiselect
                                            :allowEmpty="false"
                                            class="mb-2 multSelectGesto"
                                            :custom-label="customLabelEmp"
                                            v-model="empLibroRetSelected"
                                            selectedLabel=""
                                            deselectLabel=""
                                            selectLabel=""
                                            placeholder="Seleccionar Empresa" 
                                            label="nombreEmpresa" 
                                            track-by="codEmpresa" 
                                            :options="empresasInfo" 
                                            :multiple="false"
                                            :loading="isLoadingEmpresasInfo"
                                            @select="seleccionarEmpresaLibroRet"></multiselect>
                                    </CCol>
                                    <!--<CCol md="2">
                                        <multiselect 
                                            class="m-0 d-inline-block mb-2" 
                                            v-model="anioLibroRetSelected"
                                            deselect-label="" 
                                            placeholder="Año" 
                                            select-label=""
                                            selectedLabel=""
                                            :options="anios" 
                                            :searchable="true"
                                            :disabled="empLibroRetSelected == null"
                                            @select="seleccionarAnioLibroRet"
                                        >
                                        </multiselect>
                                    </CCol>
                                    <CCol md="2">
                                        <multiselect class="m-0 d-inline-block mb-2" 
                                            v-model="mesLibroRetSelected"
                                            deselect-label="" 
                                            placeholder="Mes" 
                                            select-label=""
                                            selectedLabel=""
                                            label="nombre" 
                                            track-by="valor" 
                                            :options="meses" 
                                            :searchable="true"
                                            :disabled="empLibroRetSelected == null"
                                            @select="seleccionarMesLibroRet"
                                        >
                                        </multiselect>
                                    </CCol>-->
                                    <CCol md="2">
                                        <date-picker
                                            style="width: 100%;"
                                            :editable="false"
                                            :clearable="false"
                                            default-panel="year"
                                            :disabled-date="disabledDateLibRet"
                                            type="year"
                                            class="mb-2"
                                            :lang="langRetencionesPicker" 
                                            v-model="anioPickerSelectedLibRet"
                                            :confirm="false" 
                                            :confirm-text="'Aceptar'"
                                            @change="seleccionarAnioLibroRetPicker"></date-picker>
                                    </CCol>
                                    <CCol md="2">
                                        <date-picker
                                            style="width: 100%;"
                                            :editable="false"
                                            :clearable="false"
                                            :format="'MMMM'"
                                            :default-value="new Date(anioPickerSelectedLibRet)"
                                            default-panel="month"
                                            :disabled-date="disabledMesLibRet"
                                            type="month"
                                            class="mb-2"
                                            :lang="langRetencionesPicker" 
                                            v-model="mesPickerSelectedLibRet"
                                            :confirm="false" 
                                            :confirm-text="'Aceptar'"
                                            @change="seleccionarMesLibroRetPicker"></date-picker>
                                    </CCol>
                                </CRow>
                                <CRow v-if="isLoadingLibroRetencion">
                                    <CCol md="12">
                                        <center>
                                            <img src="/img/logo2.png" size="custom-size" :height="50" class="imgr mt-3 mb-3" /> Obteniendo Información...
                                        </center>
                                    </CCol>
                                </CRow>
                                <CRow v-else style="overflow-x:auto" v-show="infoHonorariosLibroRet.length > 0">
                                    <CCol col="12">
                                        <table class="tableGesto mt-2" v-show="!isLoadingEstadoResultados">
                                            <thead>
                                                <th v-for="col in columnasLibroRetenciones" v-bind:key="col.scopedSlots.customRender" :style="{'text-align': col.align}">{{col.title}}</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="libRet in infoHonorariosLibroRet" v-bind:key="libRet.correlativo">
                                                    <td width="9%">{{libRet.tipo}}</td>
                                                    <td width="10%">{{libRet.fecha}}</td>
                                                    <td width="5%" style="text-align: center;">{{libRet.correlativo}}</td>
                                                    <td width="5%" style="text-align: center;">{{libRet.nroBoleta}}</td>
                                                    <td width="9%"><a style="color:#29235C" class="font-weight-bold" @click="seleccionarRutTrabajador(libRet.rutProfesional, libRet.nombreProfesional)">{{formatearRut(libRet.rutProfesional)}}</a></td>
                                                    <td width="10%">{{libRet.nombreProfesional}}</td>
                                                    <td width="8%">{{libRet.glosa}}</td>
                                                    <td width="12%" style="text-align: right;">{{libRet.montoBruto | currency}}</td>
                                                    <td width="8%" style="text-align: center;">{{libRet.porcentaje | currency(configPorcentaje)}}</td>
                                                    <td width="12%" style="text-align: right;">{{libRet.retencion | currency}}</td>
                                                    <td width="12%" style="text-align: right;">{{libRet.total | currency}}</td>
                                                
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style="text-align:right;" >{{(totalesLibRet != null)?totalesLibRet.montoBruto:"0" | currency}}</td>
                                                    <td></td>
                                                    <td style="text-align:right;" >{{(totalesLibRet != null)?totalesLibRet.retencion:"0" | currency}}</td>
                                                    <td style="text-align:right;" >{{(totalesLibRet != null)?totalesLibRet.total:"0" | currency}}</td>
                                                    
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <!--<table class="tableGesto mt-2" v-show="!isLoadingEstadoResultados">
                                            <thead>
                                                <th v-for="col in columnasLibroRetenciones" 
                                                    v-bind:key="col.scopedSlots.customRender" 
                                                    :style="{'text-align': col.align}">
                                                    {{col.title}}
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="libRet in infoHonorariosLibroRet" v-bind:key="libRet.correlativo">
                                                    <td width="9%" height="10px">{{libRet.tipo}}</td>
                                                    <td width="9%" height="10px">{{libRet.fecha}}</td>
                                                    <td width="9%" height="10px" style="text-align: center;">{{libRet.correlativo}}</td>
                                                    <td width="9%" height="10px" style="text-align: center;">{{libRet.nroBoleta}}</td>
                                                    <td width="9%" height="10px">{{libRet.rutProfesional}}</td>
                                                    <td width="9%" height="10px">{{libRet.nombreProfesional}}</td>
                                                    <td width="9%" height="10px">{{libRet.glosa}}</td>
                                                    <td width="9%" height="10px" style="text-align: right;">{{libRet.montoBruto | currency}}</td>
                                                    <td width="9%" height="10px" style="text-align: center;">{{libRet.porcentaje | currency(configPorcentaje)}}</td>
                                                    <td width="9%" height="10px" style="text-align: right;">{{libRet.retencion | currency}}</td>
                                                    <td width="9%" height="10px" style="text-align: right;">{{libRet.total | currency}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style="text-align:right;" >{{(totales != null)?totales.montoBruto:"0" | currency}}</td>
                                                    <td></td>
                                                    <td style="text-align:right;" >{{(totales != null)?totales.retencion:"0" | currency}}</td>
                                                    <td style="text-align:right;" >{{(totales != null)?totales.total:"0" | currency}}</td>
                                                    
                                                </tr>
                                            </tfoot>
                                        </table>-->
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                            </CCardFooter>
                        </CCard>
                    </CTab>
                     <CTab>
                        <template slot="title">
                            Retenciones por Rut
                        </template>
                        <CCard class="tarjetaGestoRetenciones mt-2">
                            <CCardHeader>
                                <h4>Informe de Retenciones por Rut</h4>
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol md="4">
                                        <multiselect
                                            :allowEmpty="false"
                                            class="mb-2 multSelectGesto"
                                            :custom-label="customLabelEmp"
                                            v-model="emRetencionRutSelected"
                                            selectedLabel=""
                                            deselectLabel=""
                                            selectLabel=""
                                            placeholder="Seleccionar Empresa" 
                                            label="nombreEmpresa"
                                            :loading="isLoadingEmpresasInfo"
                                            track-by="codEmpresa" 
                                            :options="empresasInfo" 
                                            :multiple="false"
                                            @select="seleccionarEmpresaRetencionRut"></multiselect>
                                    </CCol>
                                    <CCol md="3">
                                        <date-picker
                                            :editable="false"
                                            :clearable="false"
                                            class="mb-2"
                                            :lang="langRetencionesPicker"
                                            format="DD-MM-YYYY"
                                            v-model="fechaBusqueda"
                                            range
                                            :confirm="false"
                                            :confirm-text="'Aceptar'"
                                            :disabled-date="deshabilitarDesde"
                                            @change="seleccionarRangoFechasRetencionRut">
                                            <template v-slot:header="{ emit }">
                                                <button class="mx-btn mx-btn-text" 
                                                    :disabled="!btnShortcutsHab.btnMesActual" 
                                                    @click="seleccionarMesActual()">
                                                    Mes Actual ({{ langRetencionesPicker.formatLocale.monthsShort[new Date().getMonth()] }})
                                                </button>
                                                |
                                                <button class="mx-btn mx-btn-text" 
                                                    :disabled="!btnShortcutsHab.btnAnioActual" 
                                                    @click="seleccionarAnioActual()">
                                                    Año Actual ({{ new Date().getFullYear() }})
                                                </button>
                                                |
                                                <button class="mx-btn mx-btn-text" 
                                                :disabled="!btnShortcutsHab.btnAnioAnterior" 
                                                @click="seleccionarAnioAnterior()">
                                                    Año Anterior ({{ new Date().getFullYear()-1 }})
                                                </button>
                                                
                                            </template>
                                        </date-picker>
                                    </CCol>
                                    <CCol md="3">
                                        <multiselect
                                            class="multSelectGesto mb-2"
                                            v-model="rutTrabajadoresSelected" 
                                            :options="rutTrabajadores" 
                                            select-label=""
                                            selectedLabel=""
                                            deselect-label=""
                                            selectGroupLabel=""
                                            deselectGroupLabel=""
                                            :multiple="true" 
                                            placeholder="Mostrar por Rut" 
                                            track-by="rutTrabajador"
                                            :custom-label="customLabelTrabajador"
                                            :loading="isLoadingTrabajadores"
                                            :show-labels="true"
                                            @input="actualizarFiltroRuts">
                                            <template slot="tag" slot-scope="props">
                                                <span class="multiselect__tag">
                                                    <span>{{formatearRut(props.option.rutTrabajador)}}</span> 
                                                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="quitarTag(props.option)"></i>
                                                </span>
                                            </template>
                                        </multiselect>
                                    </CCol>
                                </CRow>
                                <CRow v-if="isLoadingInfoRetencionRut">
                                    <CCol md="12">
                                        <center>
                                            <img src="/img/logo2.png" size="custom-size" :height="50" class="imgr mt-3 mb-3" /> Obteniendo Información...
                                        </center>
                                    </CCol>
                                </CRow>
                                <div v-else style="overflow-x:auto" v-show="infoRetencionesRut.length > 0">
                                    <div v-for="retRut in infoRetencionesRut" v-bind:key="retRut.rut">
                                        <div>
                                            <span class="mr-3 font-weight-bold">{{formatearRut(retRut.rut)}}</span> 
                                            <span class="text-capitalize font-weight-bold">{{retRut.nombre}}</span>
                                        </div>
                                        <table class="tableGesto mt-2">
                                            <thead>
                                                <th v-for="col in columnasRetencionesRut" :width="col.width" v-bind:key="col.scopedSlots.customRender" :style="{'text-align': col.align}">{{col.title}}</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="ret in retRut.retenciones" v-bind:key="ret.nroBoleta">
                                                    <td style="overflow: hidden;text-overflow: ellipsis;">{{ret.tipo}}</td>
                                                    <td style="overflow: hidden;text-overflow: ellipsis;">{{ret.tipoRetencion}}</td>
                                                    <td>{{ret.periodoContable}}</td>
                                                    <td>{{ret.fechaDocumento}}</td>
                                                    <td>{{ret.fechaDeclaracion}}</td>
                                                    <td style="text-align: center;">{{ret.nroBoleta}}</td>
                                                    <td style="text-overflow: ellipsis;">{{ret.glosa}}</td>
                                                    <td style="text-align: right;">{{ret.montoBruto | currency}}</td>
                                                    <td style="text-align: center;">{{ret.porcentaje | currency(configPorcentaje)}}</td>
                                                    <td style="text-align: right;">{{ret.retencion | currency}}</td>
                                                    <td style="text-align: right;">{{ret.total | currency}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td colspan="3" class="font-weight-bold">Total {{retRut.nombre}}</td>
                                                    <td style="text-align:right;" >{{retRut.totales.sumMontoBruto | currency}}</td>
                                                    <td></td>
                                                    <td style="text-align:right;" >{{retRut.totales.sumRetencion | currency}}</td>
                                                    <td style="text-align:right;" >{{retRut.totales.sumTotal | currency}}</td>
                                                    <!--<td style="text-align:right;" >{{(totalesRetRut != null)?totalesRetRut.montoBruto:"0" | currency}}</td>
                                                    <td></td>
                                                    <td style="text-align:right;" >{{(totalesRetRut != null)?totalesRetRut.retencion:"0" | currency}}</td>
                                                    <td style="text-align:right;" >{{(totalesRetRut != null)?totalesRetRut.total:"0" | currency}}</td>
                                                    -->
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <table class="tableGesto mt-2">
                                        <thead>
                                            <th v-for="col in columnasRetencionesRut" :width="col.width" v-bind:key="col.scopedSlots.customRender" :style="{'text-align': col.align}"></th>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <td  colspan="9" width="66%" class="font-weight-bold text-right">Total General</td>
                                                <td width="9%" style="text-align:right;" >{{(totalesRetRut != null)?totalesRetRut.sumMontoBruto:0 | currency}}</td>
                                                <td width="7%"></td>
                                                <td width="9%" style="text-align:right;" >{{(totalesRetRut != null)?totalesRetRut.sumRetencion:0 | currency}}</td>
                                                <td width="9%" style="text-align:right;" >{{(totalesRetRut != null)?totalesRetRut.sumTotal:0 | currency}}</td>
                                                
                                                
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>


                            </CCardBody>
                        </CCard>
                     </CTab>
                </CTabs>
            </CCol>
        </CRow>
        
        <Modal
            v-model="verModalDocumentos"
            :title="'Vista previa del documento'"
            :modal-style="{ 'min-width': '300px', 'max-width': '1140px' }"
            :enableClose="false"
            @close="cerrarModal">
            <CRow>
                <CCol md="12">
                    Generar PDF <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                        :checked="selectPdf"
                        @update:checked="selectPdf = !selectPdf;cambiotipoDoc('pdf')"
                    />
                    Generar CSV <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                        :checked="selectCsv"
                        @update:checked="selectCsv = !selectCsv;cambiotipoDoc('csv')"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol md="5" v-show="selectPdf">
                    Generar con firmas <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2 switchGesto"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                        :checked="generarConFirmas"
                        @update:checked="generarConFirmas = !generarConFirmas"
                    />
                    Generar con Art. 100 <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2 switchGesto"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                        :checked="generarConArt100"
                        @update:checked="generarConArt100 = !generarConArt100"
                    />
                </CCol>
                <CCol md="2">
                    
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="6" md="6" lg="6">
                    <CButton
                        size="sm"
                        v-show="selectPdf || selectCsv"
                        variant="ghost"
                        class="btnPrincipalVapp mb-1 mr-1"
                        @click="generarDocumento"
                        :disabled="isLoadingPdf">
                            <CIcon v-show="isLoadingPdf" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                            Generar Documento
                    </CButton>
                    <CButton
                        v-show="documentoGenerado"
                        size="sm"
                        variant="ghost"
                        class="btnPrincipalVapp mb-1 mr-1"
                        @click="descargarDocumento"
                        :disabled="isLoadingDocumento"
                        v-c-tooltip.hover="{content: 'Descargar Documento generado',placement: 'rigth'}">
                            <CIcon size="sm" :content="$options.freeSet.cilCloudDownload" />
                    </CButton>
                    
                </CCol>
            </CRow>
            <CRow v-show="isLoadingDocumento">
                <CCol>
                    <center>
                        <!--<CIcon v-show="isLoadingDocumento" :content="$options.freeSet.cilLoopCircular" class="imgr" style="height: 50px; width: 50px;" />-->
                        <img src="/img/logo2.png" :height="50" class="imgr mt-3" /> Generando...
                    </center>
                </CCol>
            </CRow>
            
            <!--<CIcon v-show="isLoadingPdf || isLoadingCsv" :content="$options.freeSet.cilLoopCircular" class="imgr" style="height: 100px; width: 100px;" />-->
            <iframe
                v-show="!isLoadingDocumento && documentoGenerado"
                :src="docSeleccionado.linkView"
                width="100%"
                height="400"
                allow="autoplay"
                ></iframe>
            <CRow>
                <CCol sm="12" md="12" lg="12">
                    <CButton
                        md="3"
                        class="btnPrincipalVapp m-3 float-right"
                        style="margin: 20%"
                        @click="cerrarModal">
                        Cerrar
                    </CButton>
                </CCol>
            </CRow>
        </Modal>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import axios from "axios"

    import ActualizarEmpresas from "@/views/utils/ActualizarEmpresas";

    import Multiselect from 'vue-multiselect'

    import VueModal from '@kouts/vue-modal';
    import '@kouts/vue-modal/dist/vue-modal.css';
    
    import { freeSet } from '@coreui/icons'

    import moment from 'moment'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'
    window.locale = 'es'

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        name: 'DetalleHonorarios',
        freeSet,
        components: {
            Multiselect,
            'Modal': VueModal,
            ActualizarEmpresas,
            DatePicker       
        },
        data () {
            return {                
                urlBase: cons.port+"://" + cons.ipServer,
                
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                tipoUserAdmin: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
                idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
                
                /*Globales*/
                actTab: 0,
                tabActivaRetAnuales: true,
                configPorcentaje: {
                    symbol: '%',
                    thousandsSeparator: '.',
                    fractionCount: 2,
                    fractionSeparator: ',',
                    symbolPosition: 'back',
                    symbolSpacing: false,
                    avoidEmptyDecimals: undefined,
                },
                empresasInfo: [],
                isLoadingEmpresasInfo: false,
                /*fin Globales*/

                /*Retenciones Anuales*/
                isLoadingRetAnuales: false,

                periodosRetAnuales: [],
                isLoadionPeriodosRetAnuales: false,
                
                empRetAnualesSelected: null,
                anioPickerSelected: null,
                
                totales: null,
                infoHonorarios: [],

                columnasHonorarios: [
                    { title: 'Mes', scopedSlots: { customRender: 'mes' }, width: '16%', align: 'left', ellipsis: true},
                    { title: 'Monto Renta', scopedSlots:{ customRender: 'montoRenta' }, width: '16%', align: 'right', ellipsis: true},
                    { title: 'Retencion', scopedSlots: { customRender: 'retencion' }, width: '16%', align: 'right', ellipsis: false},
                    { title: 'Factor', scopedSlots: { customRender: 'factor' }, width: '16%', align: 'center', ellipsis: false},
                    { title: 'Mon. Actualizado', scopedSlots: { customRender: 'montoAct' }, width: '16%', align: 'right', ellipsis: false},
                    { title: 'Ret. Actualizada', scopedSlots: { customRender: 'retAct' }, width: '16%', align: 'right', ellipsis: false}
                ],
                /*fin Retenciones Anuales*/

                /*Libro de Retenciones*/

                periodosLibRet: [],
                isLoadingPeriodosLibRet: false,

                anioPickerSelectedLibRet: null,
                anioObjtLibRetSel: null,

                mesPickerSelectedLibRet: null,
                mesObjtLibRetSel: null,

                
                isLoadingLibroRetencion: false,
                
                empLibroRetSelected: null,
                mesLibroRetSelected: null,
                anioLibroRetSelected: null,

                totalesLibRet: null,
                infoHonorariosLibroRet: [],
                columnasLibroRetenciones: [
                    { title: 'Tipo', scopedSlots: { customRender: 'tipo' }, width: '9%', align: 'left', ellipsis: true},
                    { title: 'Fecha', scopedSlots:{ customRender: 'fecha' }, width: '9%', align: 'left', ellipsis: true},
                    { title: 'Correl.', scopedSlots: { customRender: 'correlativo' }, width: '9%', align: 'center', ellipsis: false},
                    { title: 'N° Bol.', scopedSlots: { customRender: 'nroBoleta' }, width: '9%', align: 'center', ellipsis: false},
                    { title: 'Rut', scopedSlots: { customRender: 'rutProfesional' }, width: '9%', align: 'left', ellipsis: false},
                    { title: 'Nombre', scopedSlots: { customRender: 'nombreProfesional' }, width: '9%', align: 'left', ellipsis: false},
                    { title: 'Glosa', scopedSlots: { customRender: 'glosa' }, width: '9%', align: 'left', ellipsis: false},
                    { title: 'Monto', scopedSlots: { customRender: 'montoBruto' }, width: '9%', align: 'right', ellipsis: false},
                    { title: '%', scopedSlots: { customRender: 'porcentaje' }, width: '9%', align: 'center', ellipsis: false},
                    { title: 'Retencion', scopedSlots: { customRender: 'retencion' }, width: '9%', align: 'right', ellipsis: false},
                    { title: 'Total', scopedSlots: { customRender: 'total' }, width: '9%', align: 'right', ellipsis: false}
                ],
                /*fin Libro de Retenciones*/

                /*Retenciones por Rut*/
                isLoadingTrabajadores: false,
                isLoadingInfoRetencionRut: false,

                isLoadingRetencionRut: false,
                isLoadionPeriodosRetAnuales: false,
                fechasDeshabilitadas: [],
                periodosRetAnuales: [],

                periodosRetencionRut: [],

                emRetencionRutSelected: null,
                btnShortcutsHab: {
                    "btnMesActual": false,
                    "btnAnioActual": false,
                    "btnAnioAnterior": false
                },
                fInicioFechas: null,
                fFinFechas: null,
                fechaBusqueda: null,
                rutTrabajadoresSelected: null,
                rutTrabajadores: [],
                infoRetencionesRut: [],
                totalesRetRut: null,
                shortcuts: [
                    {
                        text: 'Today',
                        onClick() {
                            const date = new Date();
                            // return a Date
                            return date;
                        },
                    },
                    {
                        text: 'Yesterday',
                        onClick() {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            return date;
                        },
                    },
                ],
                columnasRetencionesRut: [
                    { title: 'Tipo', scopedSlots: { customRender: 'tipo' }, width: '9%', align: 'left', ellipsis: true},
                    { title: 'Tipo Ret', scopedSlots: { customRender: 'tipoRet' }, width: '9%', align: 'left', ellipsis: true},
                    { title: 'Per. Cont.', scopedSlots: { customRender: 'perCont' }, width: '9%', align: 'left', ellipsis: true},
                    { title: 'Fecha Docto.', scopedSlots: { customRender: 'fechDoc' }, width: '9%', align: 'left', ellipsis: true},
                    { title: 'Fecha Decl', scopedSlots: { customRender: 'fechaDecl' }, width: '9%', align: 'left', ellipsis: true},
                    { title: 'N° Bol.', scopedSlots: { customRender: 'numBol' }, width: '7%', align: 'center', ellipsis: true},
                    { title: 'Glosa', scopedSlots: { customRender: 'glosa' }, width: '9%', align: 'left', ellipsis: true},
                    { title: 'Bruto', scopedSlots: { customRender: 'bruto' }, width: '9%', align: 'right', ellipsis: true},
                    { title: '%', scopedSlots: { customRender: 'porc' }, width: '7%', align: 'center', ellipsis: true},
                    { title: 'Retencion', scopedSlots: { customRender: 'ret' }, width: '9%', align: 'right', ellipsis: true},
                    { title: 'Total', scopedSlots: { customRender: 'total' }, width: '9%', align: 'right', ellipsis: true},
                    
                ],
                langRetencionesPicker: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                    formatLocale: {
                        // MMMM
                        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                        // MMM
                        monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                        // dddd
                        weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
                        // ddd
                        weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                        // dd
                        weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
                        
                    },
                },
                /*fin Retenciones por Rut*/

                totalPagina: 20,

                comparativo: false,
                
                isLoadingEstadoResultados: false,
                isLoadingPdf: false,
                isLoadingCsv: false,

                docSeleccionado: {
                    "link": "",
                    "nombre": "",
                    "linkView": ""
                },
                verModalDocumentos: false,

                generarConFirmas: false,
                generarConArt100: false,

                selectPdf: false,
                selectCsv: false,

                isLoadingDocumento: false,
                documentoGenerado: false,



            }
        },
        created: function(){
            
        },
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }

        },
        mounted(){
            this.obtenerSoloInfoEmpresas()
        },
        methods: {
            
            /*Retenciones Anuales*/
            testDisabledDate: function(date, currentValue){
                let anio = format(date, "yyyy", {locale: es})
                let anioNoEncontrado = true
                for (const periodo of this.periodosRetAnuales) {
                    if(anio == periodo.anio){
                        anioNoEncontrado = false
                    }
                }
                return anioNoEncontrado
            },
            
            seleccionarAnio: function(anio){                
                if(this.anioPickerSelected != null){
                    this.getInformacion()
                }
            },
            getPerEmpSelRetAnuales: function(){
                //this.empRetAnualesSelected, this.periodosRetAnuales, this.isLoadionPeriodosRetAnuales
                var url = this.urlBase + "/get_periodos_empresa_honorarios";
                this.isLoadionPeriodosRetAnuales = true
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empRetAnualesSelected.idEmpresa
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.periodosRetAnuales = result.data.periodosEmpresaHonorarios
                    this.isLoadionPeriodosRetAnuales = false
                    
                }, error => {
                    this.isLoadionPeriodosRetAnuales = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },
            getInformacion: function(){
                this.infoHonorarios = []
                this.totales = null
                this.isLoadingRetAnuales = true

                var url = this.urlBase + "/get_resumen_honorarios";
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empRetAnualesSelected.idEmpresa,
                            "anio": format(this.anioPickerSelected, "yyyy", {locale: es})
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.totalPagina = result.data.length
                    this.infoHonorarios = result.data.infoHonorarios.honorarios;
                    this.totales = result.data.infoHonorarios.totales;
                    this.isLoadingRetAnuales = false

                }, error => {
                    this.isLoadingRetAnuales = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });

            },
            seleccionarEmpresa: function(emp){
                this.empRetAnualesSelected = emp
                this.getPerEmpSelRetAnuales()
                if(this.anioPickerSelected != null)
                    this.getInformacion()
                
            },
            seleccionarMesRetAnual: function(mesSeleccionado){
                //this.empLibroRetSelected = this.empRetAnualesSelected
                this.anioPickerSelectedLibRet = new Date(this.anioPickerSelected)
                
                let anioSel = format(this.anioPickerSelected, "yyyy", {locale: es})
                this.anioObjtLibRetSel = this.periodosRetAnuales.find(anio => anio.anio == anioSel)
                
                
                this.mesObjtLibRetSel = this.anioObjtLibRetSel.meses.find(mes => mes.nombreMes == mesSeleccionado);
                
                this.mesPickerSelectedLibRet = new Date(anioSel,this.mesObjtLibRetSel.mes-1,1)//new Date(`${anioSel}-${this.mesObjtLibRetSel.mes}-01`)
                
                
                this.seleccionarEmpresaLibroRet(this.empRetAnualesSelected)
                //this.getInformacionLibroRet()
                
                this.actTab = 1
            },
            /*Fin Retenciones Anuales*/
            
            
            /*Libro de Retenciones*/
            disabledDateLibRet: function(date, currentValue){
                let anio = format(date, "yyyy", {locale: es})
                let anioNoEncontrado = true
                for (const periodo of this.periodosLibRet) {
                    if(anio == periodo.anio){
                        anioNoEncontrado = false
                    }
                }
                return anioNoEncontrado
            },
            disabledMesLibRet: function(date, currentValue){
                let mes = format(date, "M", {locale: es})
                let anio = format(date, "yyyy", {locale: es})
                let mesNoEncontrado = true
                for (const periodo of this.anioObjtLibRetSel.meses) {
                    if(mes == periodo.mes && anio == this.anioObjtLibRetSel.anio){
                        mesNoEncontrado = false
                    }
                }
                return mesNoEncontrado
            },
            getPerEmpSelLibRet: function(){
                //this.empRetAnualesSelected, this.periodosRetAnuales, this.isLoadionPeriodosRetAnuales
                var url = this.urlBase + "/get_periodos_empresa_honorarios";
                this.isLoadingPeriodosLibRet = true
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empLibroRetSelected.idEmpresa
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.periodosLibRet = result.data.periodosEmpresaHonorarios
                    this.isLoadingPeriodosLibRet = false
                }, error => {
                    this.isLoadingPeriodosLibRet = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },
            getInformacionLibroRet: function(){
                this.infoHonorariosLibroRet = []
                this.totalesLibroRet = null
                this.isLoadingLibroRetencion = true
                //this.anioLibroRetSelected = anio
                

                var url = this.urlBase + "/get_libro_retenciones";
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empLibroRetSelected.idEmpresa,
                            "mes": format(this.mesPickerSelectedLibRet, "M", {locale: es}),
                            "anio": format(this.anioPickerSelectedLibRet, "yyyy", {locale: es})
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.infoHonorariosLibroRet = result.data.infoLibroRetenciones.libroRetenciones;
                    this.totalesLibRet = result.data.infoLibroRetenciones.totales;
                    this.isLoadingLibroRetencion = false

                }, error => {
                    this.isLoadingLibroRetencion = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });

            },
            seleccionarEmpresaLibroRet: function(emp){
                this.empLibroRetSelected = emp
                this.getPerEmpSelLibRet()
                if(this.mesObjtLibRetSel != null && this.anioObjtLibRetSel != null && this.empLibroRetSelected != null){
                    this.getInformacionLibroRet()
                }
            },
            seleccionarMesLibroRet: function(mes){
                //this.validarMesHasta(mes)
                this.mesLibroRetSelected = mes
                
                if(this.mesLibroRetSelected != null && this.anioLibroRetSelected != null && this.empLibroRetSelected != null){
                    
                    this.getInformacionLibroRet(this.anioLibroRetSelected)
                }
                
            },
            seleccionarAnioLibroRetPicker: function(){
                let anioSel = format(this.anioPickerSelectedLibRet, "yyyy", {locale: es})
                this.anioObjtLibRetSel = this.periodosLibRet.find(anio => anio.anio == anioSel)
                
                if(this.anioPickerSelectedLibRet != null && this.mesPickerSelectedLibRet != null && this.empLibroRetSelected != null){
                    this.getInformacionLibroRet()
                }
            },
            seleccionarMesLibroRetPicker: function(){
                let mesSel = format(this.mesPickerSelectedLibRet, "M", {locale: es})
                this.mesObjtLibRetSel = this.anioObjtLibRetSel.meses.find(mes => mes.mes == mesSel)
                
                if(this.anioPickerSelectedLibRet != null && this.mesPickerSelectedLibRet != null && this.empLibroRetSelected != null){
                    this.getInformacionLibroRet()
                }
            },
            seleccionarAnioLibroRet: function(anio){
                this.anioLibroRetSelected = anio
                if(this.empLibroRetSelected != null && this.mesLibroRetSelected != null){
                    this.getInformacionLibroRet(this.anioLibroRetSelected)

                }
            },
            seleccionarRutTrabajador: function(rutTrabajador, nombreTrabajador){
                this.fechaBusqueda = [new Date(`${this.anioObjtLibRetSel.anio}-${this.mesObjtLibRetSel.mes}-1`), new Date(this.anioObjtLibRetSel.anio, this.mesObjtLibRetSel.mes,0)]
                
                
                this.rutTrabajadoresSelected = []
                this.rutTrabajadoresSelected.push({
                    "rutTrabajador": rutTrabajador,
                    "nombreTrabajador": nombreTrabajador
                })
                this.seleccionarEmpresaRetencionRut(this.empLibroRetSelected)
                //this.getTrabajadoresHonorarios()
                this.actTab = 2
            },
            /*fin Libro de Retenciones*/

            /*Retenciones por Rut*/
            
            seleccionarEmpresaRetencionRut: function(emp){
                this.emRetencionRutSelected = emp
                this.infoRetencionesRut = []
                this.totalesRetRut = null
                this.getPerEmpSelRetencionRut()
                //if(this.mesLibroRetSelected != null && this.anioLibroRetSelected != null && this.empLibroRetSelected != null){
                if(this.fechaBusqueda)
                    this.getTrabajadoresHonorarios()
                    
                //}

            },
            quitarTag: function(tag){
                this.rutTrabajadoresSelected.splice(this.rutTrabajadoresSelected.indexOf(tag), 1)
                
                this.getInformacionRetencionRut()
            },
            getPerEmpSelRetencionRut: function(){
                //this.empRetAnualesSelected, this.periodosRetAnuales, this.isLoadionPeriodosRetAnuales
                var url = this.urlBase + "/get_periodos_empresa_honorarios";
                this.isLoadingRetencionRut = true
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.emRetencionRutSelected.idEmpresa
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.periodosRetencionRut = result.data.periodosEmpresaHonorarios
                    this.isLoadingRetencionRut = false
                    this.fechaBusqueda = [
                        new Date(this.periodosRetencionRut[0].anio, this.periodosRetencionRut[0].meses[0].mes-1, 1), 
                        new Date(this.periodosRetencionRut[this.periodosRetencionRut.length-1].anio, this.periodosRetencionRut[this.periodosRetencionRut.length-1].meses[this.periodosRetencionRut[this.periodosRetencionRut.length-1].meses.length-1].mes-1, 0)
                        ]
                    this.getTrabajadoresHonorarios()
                }, error => {
                    this.isLoadingRetencionRut = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },
            
            getInformacionRetencionRut: function(){
                this.infoRetencionesRut = []
                this.totalesRetRut = null
                this.isLoadingInfoRetencionRut = true
                //this.anioLibroRetSelected = anio
                
                var url = this.urlBase + "/get_retenciones_rut";
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.emRetencionRutSelected.idEmpresa,
                            "fechaInicio": format(this.fechaBusqueda[0], cons.formatoFechaRangoHonorarios, {locale: es}),//this.fechaInicialRetencionRutSelected,
                            "fechaFin": format(this.fechaBusqueda[1], cons.formatoFechaRangoHonorarios, {locale: es}),
                            "rutTrabajador": this.rutTrabajadoresSelected
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.infoRetencionesRut = result.data.infoRetenciones.retenciones;
                    this.totalesRetRut = result.data.infoRetenciones.totales;
                    this.isLoadingInfoRetencionRut = false

                }, error => {
                    this.isLoadingInfoRetencionRut = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });

            },
            
            seleccionarRangoFechasRetencionRut: function(){
                if(this.emRetencionRutSelected != null && this.fechaBusqueda[0] != null && this.fechaBusqueda[1] != null){
                    this.getTrabajadoresHonorarios()
                }
            },
            actualizarFiltroRuts: function(filtro){
                if(this.emRetencionRutSelected != null && this.fechaBusqueda[0] != null && this.fechaBusqueda[1] != null){
                    this.getInformacionRetencionRut()
                }
            },
            getTrabajadoresHonorarios: function(){
                var url = this.urlBase + "/get_ruts_honorarios";
                this.isLoadingTrabajadores = true
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.emRetencionRutSelected.idEmpresa,
                            "fechaInicio": format(this.fechaBusqueda[0], cons.formatoFechaRangoHonorarios, {locale: es}),//this.fechaInicialRetencionRutSelected,
                            "fechaFin": format(this.fechaBusqueda[1], cons.formatoFechaRangoHonorarios, {locale: es}),
                            
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => { 
                    
                    this.rutTrabajadores = result.data.infoRutsHonorarios
                    this.validarExisteRut()
                    this.getInformacionRetencionRut()

                    
                    this.isLoadingTrabajadores = false

                }, error => {
                    this.isLoadingTrabajadores = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },
            validarExisteRut: function(){

                if (this.rutTrabajadoresSelected != null && this.rutTrabajadoresSelected.length > 0){
                    
                    this.rutTrabajadoresSelected.forEach(trab => {
                        
                        var existe = false
                        this.rutTrabajadores.forEach(tra => {
                            if(trab.rutTrabajador == tra.rutTrabajador){
                                existe = true
                            }
                        })
                        if(!existe){
                            this.rutTrabajadoresSelected.splice(this.rutTrabajadoresSelected.indexOf(trab), 1)
                        }
                    })
                }
            },
            customLabelTrabajador: function(trabajador){
                return `${trabajador.nombreTrabajador} (${this.formatearRut(trabajador.rutTrabajador)})`
            },
            deshabilitarDesde: function(date, currentValue){
                if(this.periodosRetencionRut == null || this.periodosRetencionRut.length == 0){
                    return true
                }
                //let mes = format(date, "M", {locale: es})
                //let anio = format(date, "yyyy", {locale: es})
                if(this.fInicioFechas == null || this.fFinFechas == null){
                
                    this.fInicioFechas = new Date(this.periodosRetencionRut[0].anio, this.periodosRetencionRut[0].meses[0].mes-1, 1)
                    this.fFinFechas = new Date(this.periodosRetencionRut[this.periodosRetencionRut.length-1].anio, this.periodosRetencionRut[this.periodosRetencionRut.length-1].meses[this.periodosRetencionRut[this.periodosRetencionRut.length-1].meses.length-1].mes-1, 0)
                    //this.fInicioFechas = new Date(2023, 1, 5)
                    //this.fFinFechas = new Date(2023, 4, 25)
                    
                    this.validarBtnesActivos()
                }
                return date < this.fInicioFechas || date > this.fFinFechas
                
            },
            validarBtnesActivos: function(){
                
                /*
                btnShortcutsHab: {
                    "btnMesActual": false,
                    "btnAnioActual": false,
                    "btnAnioAnterior": false
                },
                */
                let fechaActual = new Date();
                if(this.fFinFechas.getFullYear() >= fechaActual.getFullYear() && this.fFinFechas.getMonth() >= fechaActual.getMonth()){
                    this.btnShortcutsHab.btnMesActual = true
                }
                if(this.fFinFechas.getFullYear() >= fechaActual.getFullYear()){
                    this.btnShortcutsHab.btnAnioActual = true
                }
                if(this.fFinFechas.getFullYear() <= fechaActual.getFullYear()){
                    this.btnShortcutsHab.btnAnioAnterior = true
                }
                
            },
            seleccionarMesActual: function(){
                let fechaActual = new Date();       
                let fecha1 = null;
                let fecha2 = null;
                if(this.fInicioFechas.getMonth() <= 0 && this.fInicioFechas.getDate() <= 1){
                    fecha1 = new Date(fechaActual.getFullYear(), this.fInicioFechas.getMonth(), 1)
                } else{
                    fecha1 = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), this.fInicioFechas.getDate())
                }
                if(this.fFinFechas.getMonth() >= 11 && this.fFinFechas.getDate() >= 31){
                    fecha2 = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 31) 
                }
                else{
                    fecha2 = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), this.fFinFechas.getDate())
                                    
                } 
                this.fechaBusqueda = [fecha1, fecha2]
                            
                //if(this.fFinFechas.getFullYear() >= fechaActual.getFullYear() && this.fFinFechas.getMonth() >= fechaActual.getMonth())
                    //this.fechaBusqueda = [new Date(fechaActual.getFullYear(),fechaActual.getMonth(),1), new Date(fechaActual.getFullYear(),fechaActual.getMonth(), this.fFinFechas.getDate())] 
                    
                
            },
            seleccionarAnioActual: function(){
                let fechaActual = new Date();
                if (fechaActual >= this.fInicioFechas && fechaActual <= this.fFinFechas){
                                        
                    let fecha1 = null;
                    let fecha2 = null;
                    if(this.fInicioFechas.getMonth() <= 0 && this.fInicioFechas.getDate() <= 1)
                        fecha1 = new Date(fechaActual.getFullYear(), 0, 1)
                    else
                        fecha1 = new Date(fechaActual.getFullYear(), this.fInicioFechas.getMonth(), this.fInicioFechas.getDate())
                    
                    if(this.fFinFechas.getMonth() >= 11 && this.fFinFechas.getDate() >= 31)
                        fecha2 = new Date(fechaActual.getFullYear(), 11, 31)
                    else
                        fecha2 = new Date(fechaActual.getFullYear(), this.fFinFechas.getMonth(), this.fFinFechas.getDate())
                                
                    this.fechaBusqueda = [fecha1, fecha2]
                }



                //[new Date(`${this.anioObjtLibRetSel.anio}-${this.mesObjtLibRetSel.mes}-1`), new Date(this.anioObjtLibRetSel.anio, this.mesObjtLibRetSel.mes,0)]
                //if(this.fFinFechas.getFullYear() >= fechaActual.getFullYear())
                //    this.fechaBusqueda = [new Date(fechaActual.getFullYear(), 0,1), this.fFinFechas]
                //this.fechaBusqueda = [new Date(fechaActual.getFullYear(),0,1), this.fFinFechas]*/
                
                /*
                mes actual
                anio en curso
                anio anterior
            
                */ 
            },
            seleccionarAnioAnterior: function(){

                let fechaActual = new Date();
                let fechaActual1 = new Date(fechaActual.getFullYear()-1, fechaActual.getMonth(), fechaActual.getDate());
                
                if (fechaActual1 >= this.fInicioFechas && fechaActual1 <= this.fFinFechas){
                    
                    let fecha1 = null;
                    let fecha2 = null;
                    if(this.fInicioFechas.getMonth() <= 0 && this.fInicioFechas.getDate() <= 1){
                        fecha1 = new Date(fechaActual1.getFullYear(), 0, 1)
                    }
                    else{
                        fecha1 = new Date(fechaActual1.getFullYear(), this.fInicioFechas.getMonth(), this.fInicioFechas.getDate())
                        
                    }
                    if(this.fFinFechas.getMonth() >= 11 && this.fFinFechas.getDate() >= 31){
                        fecha2 = new Date(fechaActual1.getFullYear(), 11, 31)
                        
                    }
                    else{
                        fecha2 = new Date(fechaActual1.getFullYear(), this.fFinFechas.getMonth(), this.fFinFechas.getDate())
                        
                    }       
                    this.fechaBusqueda = [fecha1, fecha2]
                }
            },

            /*Fin Retenciones por Rut*/


            /*Globales*/

            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                var url = this.urlBase + "/get_empresas_info/CONTA";
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    //let mensaje = `Problema al obtener empresas , ${error}`
                    //this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },

            customLabelEmp (empresa) {
                return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
            },
            formatearRut: function(rut){
                var rutaFormatear = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
                let len = rutaFormatear.length - 1;
                if (len > 3) {
                    let dv = rutaFormatear[len];
                    let beforeDv = rutaFormatear.substring(0, len).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    rutaFormatear = beforeDv + '-' + dv;
                }
                return rutaFormatear;
            },


            /*Fin Globales*/

            

            generarCsv: function(){
                /*this.isLoadingDocumento = true
                this.documentoGenerado = false
                
                this.isLoadingCsv = true

                var url = this.urlBase + "/generar_csv_estado_resultado";

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anioSelected,
                            "comparativo": this.comparativo,
                            "anio2": (this.anio2Selected!=null)?this.anio2Selected.valor:null,
                            "agregarArt100": this.generarConArt100,
                            "agregarFirmas": this.generarConFirmas
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.isLoadingDocumento = false
                    
                    this.isLoadingCsv = false

                    var link = result.data.archivoGenerado.link
                    
                    this.docSeleccionado = {
                        "link": result.data.archivoGenerado.link,
                        "nombre": result.data.archivoGenerado.nombre,
                        "linkView": result.data.archivoGenerado.linkView.replace('view', 'preview'),
                    }
                    this.pdfGenerado = true
                    this.documentoGenerado = true

                    this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );

                }, error => {
                    
                    this.documentoGenerado = false
                    this.isLoadingDocumento = false
                    
                    this.isLoadingCsv = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });*/
            },

            generarPdf: function(){
                /*this.isLoadingDocumento = true
                this.documentoGenerado = false

                this.isLoadingPdf = true

                var url = this.urlBase + "/generar_pdf_estado_resultado";

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anioSelected,
                            "comparativo": this.comparativo,
                            "anio2": (this.anio2Selected!=null)?this.anio2Selected.valor:null,
                            "agregarArt100": this.generarConArt100,
                            "agregarFirmas": this.generarConFirmas
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.isLoadingDocumento = false
                    
                    this.isLoadingPdf = false

                    
                    this.docSeleccionado = {
                        "link": result.data.archivoGenerado.link,
                        "nombre": result.data.archivoGenerado.nombre,
                        "linkView": result.data.archivoGenerado.linkView.replace('view', 'preview'),
                    }
                    this.pdfGenerado = true
                    this.documentoGenerado = true

                    this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );

                }, error => {
                    this.documentoGenerado = false
                    this.isLoadingDocumento = false

                    this.isLoadingPdf = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });*/
            },

            
             

            cerrarModal: function () {
                this.verDocumento = false;
                this.verModalDocumentos = false;
            },
            cambiotipoDoc: function(tipoDoc){
                if(tipoDoc === 'pdf'){
                    if(this.selectPdf)
                        this.selectCsv = false;
                }else if(tipoDoc === 'csv'){
                    if(this.selectCsv)
                        this.selectPdf = false;
                }
            },
            descargarDocumento: function(){
                /*var doc = null
                if(tipo === 'pdf'){
                    doc = this.docPdf
                }
                else if(tipo === 'csv'){
                    doc = this.docCsv
                }*/
                var fileLink = document.createElement("a");
                fileLink.href = this.docSeleccionado.link;
                fileLink.setAttribute("download", this.docSeleccionado.nombre);
                document.body.appendChild(fileLink);
                fileLink.click();
            },
            generarDocumento: function(){
                this.docSeleccionado = {
                    "link": "",
                    "nombre": "",
                    "linkView": ""
                }
                if(this.selectPdf){
                    this.generarPdf()
                }
                else if(this.selectCsv){
                    this.generarCsv()
                }
            },

            
            
        }
        
    }
</script>

<style>

    .multSelectGesto .multiselect__content-wrapper{
        width: 100%; 
    }
    .multiselect__single{
        font-size: 14px;
    }
    .multiselect__content-wrapper{
        width: 159%; 
    }
    .imgr{
        -webkit-animation: 3s rotate linear infinite;
        animation: 3s rotate  linear infinite;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    .search-box2{
        position: absolute;
        left: 60px;
        transform: translate(0, -50%);
        background: #2c3441;
        height: 40px;
        border-radius: 40px;
    }
    .search-box2:hover > input{
        width: 200px;
        padding: 0 10px;
    }
    .icon2{
        color: #21dfcd;
        float: right;
        width: 40px;
        font-size: 1.3rem;
        height: 40px;
        border-radius: 50%;
        background: #2f3640;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.4s;
        cursor: pointer;
        text-decoration: none;
    }
    .icon2:hover{
        transform: rotate(360deg) scale(0.8);
    }
    input{
        width: 0;
        border: none;
        outline: none;
        padding: 0;
        background: none;
        font-size: 1.1rem;
        transition: 0.5s ease;
        line-height: 40px;
        color: #fff;
    }
    

    .switchGesto .c-switch-input:checked + .c-switch-slider {
        border-color: #29235C;
    }
    .switchGesto .c-switch-input:checked + .c-switch-slider::before {
        background-color: #29235C;
        border-color: #29235C;
    }
    .switchGesto .c-switch-input:checked + .c-switch-slider::after {
        color: #29235C;
    }
    .switchGesto .c-switch-slider {
        border-color: #29235C;
    }
    .switchGesto .c-switch-slider::before {
        background-color: #29235C;
        border-color: #29235C;
    }
    .switchGesto .c-switch-slider::after {
        color: #461A3B;

    }
.v-calendar .calendar{
    
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.v-calendar .calendar .range:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.mx-datepicker .mx-datepicker-range{
    width: 100%;
    height: 43px;
}
.mx-datepicker-range{
    width: 100%;
}
.mx-input-wrapper{
    height: 43px;
    width: 100%;
}
.mx-input{
    height: 43px;
    width: 100%;
}
</style>